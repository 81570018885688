import axios from 'axios';

import { applyExtractorResponseInterceptor } from '@/services/http-client/interceptors/extract-response.interceptors';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_RESTU_API_BASE_URL,
  timeout: 30 * 1000,
  withCredentials: false,
});

applyExtractorResponseInterceptor(httpClient);
