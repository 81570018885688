import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import type { ErrorResponse } from '@/api/types/common.types';
import { PaymentTypeEnum } from '@/enums/order.enum';

import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { TextInput } from '@/components/Inputs/TextInput';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';

// =================================================================

interface PaymentFormProps {
  orderId: string;
  onClose: VoidFunction;
}

type FormValues = {
  cash: string;
  card: string;
};

// =================================================================

const initialValues = {
  cash: '',
  card: '',
};

// =================================================================

export const PaymentForm = (props: PaymentFormProps) => {
  const { orderId, onClose } = props;

  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const queryClient = useQueryClient();

  const [{ isLoading, errorMsg }, setFormStatus] = useState({ isLoading: false, errorMsg: '' });

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const { card, cash } = data;
    if (!card && !cash) return;

    try {
      setFormStatus(prev => ({ ...prev, isLoading: true }));
      const payments = [] as { price: number; payment_type: PaymentTypeEnum }[];
      if (card) {
        payments.push({ price: Number(card), payment_type: PaymentTypeEnum.CARD });
      }
      if (cash) {
        payments.push({ price: Number(cash), payment_type: PaymentTypeEnum.CASH });
      }
      await OrderApi.orderPayment({ id: orderId, payments });
      if (page) {
        await queryClient.invalidateQueries({
          queryKey: OrderQueryKeys.orderList(Number(page), 15),
        });
      } else {
        await queryClient.invalidateQueries({
          queryKey: OrderQueryKeys.orderDetails(orderId),
        });
      }
      onClose();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { statusText } = error.response as ErrorResponse;
      setFormStatus(prev => ({ ...prev, errorMsg: statusText }));
    } finally {
      setFormStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <Fragment>
      <OrderDetailsCard.Title>Выберите тип оплаты</OrderDetailsCard.Title>
      {errorMsg && <Alert message={errorMsg} type="error" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="cash"
          control={control}
          render={({ field }) => {
            return (
              <TextInput label="Наличные" type="number" placeholder="Введите сумму" {...field} />
            );
          }}
        />
        <Controller
          name="card"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                label="Полученная сумма (Банковская карта)"
                type="number"
                placeholder="Введите сумму"
                className="mb-6"
                {...field}
              />
            );
          }}
        />
        <Button type="submit" variant="primary" disabled={isLoading} className="w-full">
          Оплатить
        </Button>
      </form>
    </Fragment>
  );
};
