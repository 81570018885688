import { createContext, useState, useEffect, useContext, type ReactNode } from 'react';
import { useGetSet, useUnmount } from 'react-use';

// =================================================================

export type MenuItem = {
  id: number;
  title: string;
  poster: string;
  count: number;
  price: number;
};

interface PickUpStates {
  menus: Array<MenuItem>;
}

interface PickUpMethods extends PickUpStates {
  totalPrice: number;
  increaseMenuItem: (item: MenuItem) => void;
  decreaseMenuItem: (itemId: number) => void;
  clearMenuList: VoidFunction;
}

const initialPickState: PickUpStates = {
  menus: [],
};

// =================================================================

const PickUpContext = createContext<PickUpMethods | null>(null);

// =================================================================

export const PickUpProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [getPickUpState, setPickUpState] = useGetSet<PickUpStates>(initialPickState);

  // Calculate the total price
  useEffect(() => {
    const menuList = getPickUpState().menus;
    console.log({ menuList });
    let total = 0;
    if (menuList.length !== 0) {
      total = menuList.reduce((prev, acc) => prev + acc.price * acc.count, 0);
    }

    setTotalPrice(total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPickUpState()]);

  // Increase menu list item
  const increaseMenuItem = (item: MenuItem) => {
    const menuList = getPickUpState().menus;

    const existItemIndex = menuList.findIndex(menu => menu.id === item.id);
    if (existItemIndex !== -1) {
      menuList.splice(existItemIndex, 1, {
        ...menuList[existItemIndex],
        count: menuList[existItemIndex].count + 1,
      });
    } else {
      menuList.push(item);
    }

    setPickUpState(prev => ({ ...prev, menus: menuList }));
  };

  // Decrease menu list item
  const decreaseMenuItem = (itemId: number) => {
    const menuList = getPickUpState().menus;

    const existItemIndex = menuList.findIndex(menu => menu.id === itemId);
    if (existItemIndex !== -1) {
      menuList.splice(existItemIndex, 1, {
        ...menuList[existItemIndex],
        count: menuList[existItemIndex].count - 1,
      });
    }

    setPickUpState(prev => ({ ...prev, menus: menuList }));
  };

  // Clear menu list info
  const clearMenuList = () => {
    setTotalPrice(0);
    setPickUpState(initialPickState);
  };

  useUnmount(() => {
    clearMenuList();
  });

  return (
    <PickUpContext.Provider
      value={{ ...getPickUpState(), totalPrice, increaseMenuItem, decreaseMenuItem, clearMenuList }}
    >
      {children}
    </PickUpContext.Provider>
  );
};

// =================================================================

export const usePickUpMethods = () => {
  const context = useContext(PickUpContext);

  if (!context) {
    throw new Error('usePickUpMethods should used within PickUpProvider');
  }

  return context;
};
