import { Navigate, Outlet } from 'react-router-dom';

import { Layout } from '@/layout';

import { useAuthState } from '@/contexts/AuthContext';
import { AuthPageLinks } from '@/contexts/RouterContext/router.links';

export const AuthRouterGuard = () => {
  const { isAuthenticated } = useAuthState();

  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return <Navigate to={AuthPageLinks.login} replace />;
};
