import { useRef } from 'react';

import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { Badge, type BadgeType } from '@/components/Badge';
import { type ModalProps, Modal } from '@/components/Modal';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { PaymentForm } from '@/components/OrderInfoModal/PaymentForm';

// =================================================================

interface OrderInfoModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {
  orderId: string;
}

// =================================================================

const orderStatusType: Record<OrderStatusEnum, BadgeType> = {
  NEW: 'accent',
  PAID: 'success',
  CANCELLED: 'error',
  SERVED: 'info',
};

// =================================================================

export const OrderInfoModal = (props: OrderInfoModalProps) => {
  const { onClose, isOpen, orderId, ...rest } = props;

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
    };
  }

  const { order } = useOrderDetails({ orderId });

  if (!order || !isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="max-w-[736px]"
      {...rest}
    >
      <OrderDetailsCard.Title className="mb-4">Информация о столе</OrderDetailsCard.Title>
      <OrderDetailsCard.Price
        leftText="Номер стола"
        rightText={`№${order.table_number} - Внутренный`}
        className="mb-4"
      />
      <OrderDetailsCard.Price
        leftText="Количество гостей"
        rightText={`${order.client_count} человека`}
        className="mb-4"
      />
      <OrderDetailsCard.Price
        leftText="ФИО официанта"
        rightText={order.waiter.name}
        className="mb-8"
      />
      <div className="mb-4 flex items-center justify-between">
        <OrderDetailsCard.Title>Информация о заказе</OrderDetailsCard.Title>
        <Badge
          text={order.status}
          type={orderStatusType[order.status]}
          className="px-5 py-4 text-base text-white"
        />
      </div>
      {order.menus.map(menu => (
        <OrderDetailsCard.Price
          key={menu.order_menu_id}
          leftText={`${menu.count} х “${menu.name}”`}
          rightText={`${thousandSeperator(menu.price)} сум`}
          className="mb-4"
        />
      ))}
      <div className="mb-4 mt-8 flex items-center justify-between">
        <OrderDetailsCard.Title>Итого</OrderDetailsCard.Title>
        <OrderDetailsCard.Title>{thousandSeperator(order.price)} сум</OrderDetailsCard.Title>
      </div>
      <OrderDetailsCard.Price
        leftText={`Обслуживание ${order.service_percentage}%`}
        rightText={`${thousandSeperator(order.service_price)} сум`}
        className="mb-8"
      />
      <PaymentForm orderId={orderId} onClose={onCloseRef.current} />
    </Modal>
  );
};
