import { useState, Fragment } from 'react';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';

import { PickUpConfirmModal } from '@/pages/pick-up/PickUpConfirmModal';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

export const PickUpConfirmButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { totalPrice, clearMenuList } = usePickUpMethods();

  if (totalPrice === 0) {
    return null;
  }

  return (
    <Fragment>
      <div className="fixed -bottom-8 left-0 z-10 mb-6 mt-4 flex h-28 w-full items-center justify-end border-t border-gray-300 bg-gray-100 px-20 pb-6">
        <div className="flex items-center gap-6">
          <p className="text-lg font-medium">{thousandSeperator(totalPrice)} сум</p>
          <Button variant="primary" onClick={() => setIsOpen(true)} className="text-white">
            Submit
          </Button>
          <Button variant="error" onClick={clearMenuList} className="text-white">
            Clear
          </Button>
        </div>
      </div>
      <PickUpConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Fragment>
  );
};
