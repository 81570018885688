import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useDailyStatistics } from '@/pages/cash/DailyStatistics/useDailyStatistics';

export const DailyStatistics = () => {
  const statistics = useDailyStatistics();

  if (!statistics) return null;

  return (
    <div className="grid grid-cols-4">
      <DailyStatisticsCard
        title="Наличка в кассе"
        amount={`${thousandSeperator(statistics.by_cash)} сум`}
        borderBottom
        borderRight
      />
      <DailyStatisticsCard
        title="В банковскую карту"
        amount={`${thousandSeperator(statistics.by_card)} сум`}
        borderBottom
        borderRight
      />
      <DailyStatisticsCard
        title="Через приложения"
        amount={`${thousandSeperator(statistics.by_app)} сум`}
        borderBottom
        borderRight
      />
      <DailyStatisticsCard
        title="Расход с кассы"
        amount={`${thousandSeperator(statistics.outcome_from_checkout)} сум`}
        borderBottom
      />
      <DailyStatisticsCard
        title="Выручка за день"
        amount={`${thousandSeperator(statistics.income_to_checkout)} сум`}
        borderRight
      />
      <DailyStatisticsCard
        title="Количество заказов"
        amount={`${thousandSeperator(statistics.order_count)}`}
        borderRight
      />
      <DailyStatisticsCard
        title="Средний чек"
        amount={`${thousandSeperator(statistics.average_check)} сум`}
        borderRight
      />
      <DailyStatisticsCard
        title="Процент оплаты картой"
        amount={`${thousandSeperator(statistics.percent_payment_by_card)}%`}
      />
    </div>
  );
};

// =================================================================

interface DailyStatisticsCardProps {
  amount: string;
  title: string;
  borderBottom?: boolean;
  borderRight?: boolean;
}

const DailyStatisticsCard = (props: DailyStatisticsCardProps) => {
  const { amount, title, borderBottom, borderRight } = props;

  return (
    <div className={twMerge('p-4', borderBottom && 'border-b', borderRight && 'border-r')}>
      <p className="text-lg text-gray-400">{title}</p>
      <p className="text-3xl font-semibold">{amount}</p>
    </div>
  );
};
