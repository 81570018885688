import { Fragment } from 'react';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { ExpensesTable } from '@/pages/expenses/ExpensesTable';

export const ExpensesPage = () => {
  return (
    <Fragment>
      <div className="mb-6 flex items-center justify-between">
        <PageTitle title="Расходы с кассы" />
        <div className="flex items-center gap-3">
          <Button variant="primary">Добавить ориход</Button>
          <Button variant="primary">Добавить расход</Button>
        </div>
      </div>
      <ExpensesTable />
    </Fragment>
  );
};
