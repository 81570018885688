import { useQuery } from '@tanstack/react-query';

import { MenuApi } from '@/api/domains/menu.api';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useMenuCategory = () => {
  const { data: menuCategory, isLoading } = useQuery({
    queryKey: MenuQueryKeys.menuCategory(),
    queryFn: MenuApi.menuCategory,
    staleTime: Infinity,
  });

  return { menuCategory, isLoading };
};
