import { forwardRef, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

// ----------------------------------------------------------------

interface TextInputProps extends React.ComponentProps<'input'> {
  label?: ReactNode;
  labelClassName?: string;
  wrapperClassName?: string;
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'numeric' | 'none' | 'decimal';
}

// ----------------------------------------------------------------

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    label,
    name,
    type = 'text',
    labelClassName,
    wrapperClassName,
    className,
    ...inputProps
  } = props;

  return (
    <div className={twMerge('from-control mb-3', wrapperClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={twMerge('label flex justify-start font-medium text-gray-500', labelClassName)}
        >
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={name}
        name={name}
        type={type}
        className={twMerge(
          'input input-bordered w-full border-gray-200 bg-gray-300 focus-within:outline-none focus:border-gray-400 focus:outline-none focus:ring-0',
          className,
        )}
        {...inputProps}
      />
    </div>
  );
});

// ----------------------------------------------------------------

if (process.env.NODE_ENV !== 'production') {
  TextInput.displayName = 'TextInput';
}
