export const AuthPaths = {
  ROOT_PATH: '/auth',

  LOGIN: 'login',
} as const;

// =================================================================

export const CashPaths = {
  ROOT_PATH: '/cash',
} as const;

// =================================================================

export const TablePaths = {
  ROOT_PATH: '/tables',
} as const;

// =================================================================

export const OrderPaths = {
  ROOT_PATH: '/orders',

  DETAILS: '/orders/:id',
} as const;

// =================================================================

export const MenuPaths = {
  ROOT_PATH: '/menu',
} as const;

// =================================================================

export const ExpensePaths = {
  ROOT_PATH: '/expenses',
} as const;

// =================================================================

export const PickUpPaths = {
  ROOT_PATH: '/pick-up',
} as const;
