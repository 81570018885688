import { useRef } from 'react';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';
import { type ModalProps, Modal } from '@/components/Modal';

import { PickMenuCardUI } from '@/pages/pick-up/PickUpCardUI';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

// =================================================================

interface PickUpConfirmModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {}

// =================================================================

export const PickUpConfirmModal = (props: PickUpConfirmModalProps) => {
  const { onClose, isOpen, ...rest } = props;

  const { menus, decreaseMenuItem, increaseMenuItem, totalPrice } = usePickUpMethods();

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
    };
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="max-w-[736px]"
      {...rest}
    >
      <h3 className="text-2xl font-semibold">Menu Items</h3>
      <div className="mt-8">
        <ul>
          {menus.map(menu => (
            <PickMenuCardUI
              key={menu.id}
              imageSrc={menu.poster}
              title={menu.title}
              count={menu.count}
              price={menu.price}
              handleIncreament={() => increaseMenuItem(menu)}
              handleDecreament={() => decreaseMenuItem(menu.id)}
            />
          ))}
        </ul>

        <div className="mt-6 flex justify-end">
          <div className="flex items-center gap-6">
            <p className="text-lg font-medium">{thousandSeperator(totalPrice)} сум</p>
            <Button variant="primary" className="text-white">
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
