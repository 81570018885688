import { memo } from 'react';

import { useTableList } from '@/pages/tables/useTableList';

import { TableCard } from '@/components/TableCard';

import { TableContent } from '@/pages/tables/TableContent';

interface HallTablesViewProps {
  categoryId?: number;
}

export const HallTablesView = memo((props: HallTablesViewProps) => {
  const { categoryId } = props;

  const { tableList, isLoading } = useTableList(categoryId);

  if (!categoryId || !tableList || isLoading) {
    return <h2>Loading...</h2>;
  }

  if (tableList.results.length === 0) {
    return <h2>Noting is found!</h2>;
  }

  return (
    <TableContent className="mb-8">
      {tableList.results.map(table => (
        <TableCard
          key={table.id}
          isActive={table.has_order}
          tableNumber={table.number}
          bookingInfo={table.book_table[0]}
          personCount={table.client_count}
        />
      ))}
    </TableContent>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  HallTablesView.displayName = 'HallTablesView';
}
