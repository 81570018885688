import { useEffect, Fragment, useRef } from 'react';
import { useNetworkState } from 'react-use';

import type { OrderSocketMessage } from '@/api/types/order.types';
import { IPropsChildren } from '@/types/common.types';

import { ACCESS_TOKEN } from '@/contexts/AuthContext';

export const PrinterContext = (props: IPropsChildren) => {
  const { children } = props;

  const socketRef = useRef<WebSocket | null>(null);

  const { online } = useNetworkState();

  const reconnectWebSocket = (token: string | null) => {
    if (!token) return;

    const url = process.env.REACT_APP_WEB_SOCKET_URL + '/ws/auth?authorization=bearer ' + token;
    const socket = new WebSocket(url);

    socketRef.current = socket;
  };

  const handleSubmit = async (data: OrderSocketMessage) => {
    try {
      await fetch(`${process.env.REACT_APP_LOCAL_IP}/api/printer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // const resData = await response.json();
      // console.log(resData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    if (accessToken) {
      reconnectWebSocket(accessToken);

      socketRef.current?.addEventListener('open', () => {
        socketRef.current?.send('Hello Server!');
      });

      socketRef.current?.addEventListener('message', event => {
        const data = JSON.parse(event.data);
        handleSubmit(data);
        console.log('Message from server ', data);
      });

      socketRef.current?.addEventListener('close', () => {
        console.log('socket closed');
        setTimeout(reconnectWebSocket, 1000);
      });

      return () => {
        socketRef.current?.close();
      };
    }
  }, [accessToken, online]);

  return <Fragment>{children}</Fragment>;
};
