export enum OrderStatusEnum {
  NEW = 'NEW',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  SERVED = 'SERVED',
}

// =================================================================

export enum SocketActionEnum {
  PRINTER_NEW_FOOD = 'printer_new_food',
  CHECK = 'check',
}

// =================================================================

export enum PaymentTypeEnum {
  CARD = 'CARD',
  CASH = 'CASH',
}
