import type { DailyStatistics } from '@/api/types/statistics.types';
import { httpClient } from '@/services/http-client';

export const StatisticsApi = {
  dailyStatistics: async (params?: { date: string }) => {
    const { data } = await httpClient.get<DailyStatistics>('/cashier/branch/payment_statistics', {
      params,
    });

    return data;
  },
};
