import { PageTitle } from '@/components/PageTitle';

import { MenuList } from '@/pages/pick-up/MenuList';
import { PickUpConfirmButton } from '@/pages/pick-up/PickUpConfirmButton';
import { PickUpProvider } from '@/pages/pick-up/PickUpProvider';
import { PickUpSearchBar } from '@/pages/pick-up/PickUpSearchBar';

// =================================================================

export const PickUpPage = () => {
  return (
    <PickUpProvider>
      <PageTitle title="Подобрать" className="mb-6" />
      <PickUpSearchBar />
      <MenuList />
      <div className="mt-6 h-20">
        <PickUpConfirmButton />
      </div>
    </PickUpProvider>
  );
};
