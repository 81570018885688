import { useState, useRef, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { TableApi } from '@/api/domains/table.api';
import type { ErrorResponse } from '@/api/types/common.types';

import { useTableList } from '@/pages/tables/useTableList';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal';
import { Select } from '@/components/Select';

// =================================================================

type FormValues = {
  table: {
    label: string;
    value: string;
  }[];
};

interface GenerateQrCodeModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {}

// =================================================================

const initialValues = {
  table: [{ label: '', value: '' }],
};

// =================================================================

export const GenerateQrCodeModal = (props: GenerateQrCodeModalProps) => {
  const { onClose, isOpen, ...rest } = props;

  const [{ isLoading, errorMsg }, setFormStatus] = useState({ isLoading: false, errorMsg: '' });

  const { tableList } = useTableList();

  const tableListOptions = useMemo(() => {
    if (!tableList) {
      return undefined;
    }

    return tableList.results.map(table => ({
      value: table.id.toString(),
      label: table.number.toString(),
    }));
  }, [tableList]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
      reset();
      setFormStatus({ isLoading: false, errorMsg: '' });
    };
  }

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { table } = data;
      setFormStatus(prev => ({ ...prev, isLoading: true }));

      console.log({ table });

      const tableIds = table.map(item => Number(item.value));

      const { data: url } = await TableApi.qrCodeGenerate(tableIds);
      const linkEl = document.createElement('a');
      linkEl.href = url;
      linkEl.download = 'QrCode';

      linkEl.click();

      onCloseRef.current?.();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { statusText } = error.response as ErrorResponse;
      setFormStatus(prev => ({ ...prev, errorMsg: statusText }));
    } finally {
      setFormStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseRef.current} canOutsideClickClose={false} {...rest}>
      <Modal.Title className="mb-6 text-center">Забронировать стол</Modal.Title>
      {errorMsg && <Alert message={errorMsg} type="error" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="table"
          control={control}
          render={({ field }) => {
            const { value, ...rest } = field;
            return (
              <Select
                {...rest}
                isMulti
                required
                label="Выберите стол"
                placeholder="Выберите стол"
                closeMenuOnSelect
                className="mb-6"
                options={tableListOptions}
              />
            );
          }}
        />
        <Button type="submit" variant="primary" disabled={isLoading} className="w-full">
          Скачать QR код
        </Button>
      </form>
    </Modal>
  );
};
