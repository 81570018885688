import { useQuery } from '@tanstack/react-query';

import { TableApi } from '@/api/domains/table.api';

import { TableQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useTableList = (id?: number) => {
  const { data, isLoading } = useQuery({
    queryKey: TableQueryKeys.tableList(id || ''),
    queryFn: () => TableApi.tableList(id),
    staleTime: Infinity,
  });

  return { tableList: data, isLoading };
};
