import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AuthApi } from '@/api/domains/auth.api';
import type { ErrorResponse } from '@/api/types/common.types';

import { useAuthMethods } from '@/contexts/AuthContext';
import { CashPageLinks } from '@/contexts/RouterContext/router.links';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { TextInput } from '@/components/Inputs/TextInput';
import { Logo } from '@/components/Logo';

// =================================================================

type LoginFormValues = {
  phone: string;
  password: string;
};

// =================================================================

export const LoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const { persistAccessToken, setUserCredentials } = useAuthMethods();

  const { handleSubmit, control } = useForm<LoginFormValues>({
    defaultValues: {
      phone: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<LoginFormValues> = async data => {
    const { phone, password } = data;

    try {
      setIsLoading(true);
      const { token, ...userCredentials } = await AuthApi.login(phone, password);
      persistAccessToken(token);
      setUserCredentials(userCredentials);
      navigate(CashPageLinks.main, { replace: true });
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      const { error: errorMsg } = error.response as ErrorResponse;
      setErrMsg(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-primary">
      <form onSubmit={handleSubmit(onSubmit)} className="w-[540px] rounded-2xl bg-white p-16">
        <div className="mb-8 text-center">
          <Logo />
        </div>
        <h3 className="mb-6 text-center text-2xl font-medium">Вход в личный кабинет</h3>
        {errMsg && <Alert message={errMsg} type="error" />}
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextInput
                autoFocus
                type="login"
                label="Логин:"
                placeholder="Введите логин"
                {...field}
              />
            );
          }}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextInput
                type="login"
                label="Пароль:"
                placeholder="Введите пароль"
                className="mb-6"
                {...field}
              />
            );
          }}
        />
        <Button variant="primary" disabled={isLoading} className="w-full text-white" type="submit">
          Войти
        </Button>
      </form>
    </div>
  );
};
