import { useQuery } from '@tanstack/react-query';

import { MenuApi } from '@/api/domains/menu.api';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useMenuList = (id?: string | null) => {
  const { data: menuList, isLoading } = useQuery({
    queryKey: MenuQueryKeys.menuList(id),
    queryFn: () => MenuApi.menuList(id ? { category_id: id } : {}),
    staleTime: Infinity,
  });

  return { menuList, isLoading };
};
