import { ColumnDef } from '@tanstack/react-table';
import { Fragment, memo, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import type { OrderList } from '@/api/types/order.types';
import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderList } from '@/pages/orders/useOrderList';

import { OrderPageLinks } from '@/contexts/RouterContext/router.links';

import { Badge, BadgeType } from '@/components/Badge';
import { Button } from '@/components/Button';
import { OrderInfoModal } from '@/components/OrderInfoModal';
import { Table } from '@/components/Table';

import { OrderPrintoutButton } from '@/pages/orders/OrderPrintoutButton';
import { OrderSearchBar } from '@/pages/orders/OrderSearchBar';

// =================================================================

const orderStatusType: Record<OrderStatusEnum, BadgeType> = {
  NEW: 'accent',
  PAID: 'success',
  CANCELLED: 'error',
  SERVED: 'info',
};

// =================================================================

export const OrdersTable = memo(() => {
  const [orderId, setOrderId] = useState('');

  const { orders, isLoading, isPlaceholderData, pagination, setPagination } = useOrderList();

  const columns: ColumnDef<OrderList>[] = useMemo(() => {
    return [
      {
        accessorKey: 'number',
        cell: props => {
          const number = props.getValue() as number;
          return (
            <Link
              to={OrderPageLinks.details(number)}
              className="border-b border-white transition-all duration-300 hover:border-primary hover:text-primary"
            >
              {number}
            </Link>
          );
        },
        header: '№ Заказа',
      },
      {
        accessorKey: 'table_number',
        header: 'Стол',
      },
      {
        accessorKey: 'menus',
        cell: props => {
          const menus = props.getValue() as string[];
          return <div className="max-w-96 whitespace-normal">{menus.join(', ')}</div>;
        },
        header: 'Блюдо',
      },
      {
        accessorKey: 'price',
        cell: props => `${thousandSeperator(props.getValue() as number)} сум`,
        header: 'Сумма',
      },
      {
        accessorKey: 'status',
        cell: props => {
          const status = props.getValue() as OrderStatusEnum;

          return <Badge text={status} type={orderStatusType[status]} className="text-white" />;
        },
        header: 'Статус оплаты',
      },
      {
        accessorKey: 'created_at',
        header: 'Время',
      },
      {
        header: 'Счет',
        accessorFn: row => {
          return { id: row.id, status: row.status };
        },
        cell: props => {
          const { id, status } = props.getValue() as { id: number; status: OrderStatusEnum };

          if (status === OrderStatusEnum.NEW) {
            return <OrderPrintoutButton orderId={id} />;
          }

          return null;
        },
      },
      {
        accessorFn: row => {
          return { id: row.id, status: row.status };
        },
        cell: props => {
          const { id, status } = props.getValue() as { id: number; status: OrderStatusEnum };

          if (status === OrderStatusEnum.NEW) {
            return (
              <Button variant="primary" onClick={() => setOrderId(`${id}`)} className="text-base">
                Закрыть заказ
              </Button>
            );
          }

          return null;
        },
        header: 'Действие',
      },
    ];
  }, []);

  if (!orders && isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Fragment>
      <Table
        columns={columns}
        pageCount={orders!.last_page + 1}
        state={{ pagination }}
        data={orders!.results}
        isLoading={isLoading || isPlaceholderData}
        onPaginationChange={setPagination}
        searchBar={<OrderSearchBar />}
      />
      <OrderInfoModal orderId={orderId} isOpen={Boolean(orderId)} onClose={() => setOrderId('')} />
    </Fragment>
  );
});

if (process.env.NODE_ENV !== 'production') {
  OrdersTable.displayName = 'OrdersTable';
}
