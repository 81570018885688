import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { MenuApi } from '@/api/domains/menu.api';
import { MenuStatusEnum } from '@/enums/menu.enum';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal';

// =================================================================

interface MenuStatusChangeModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {
  menuInfo: { id: number; name: string; status: MenuStatusEnum } | null;
}

// =================================================================

const statusText: Record<MenuStatusEnum, string> = {
  active: 'активировать',
  inactive: 'инактивировать',
};

// =================================================================

export const MenuStatusChangeModal = (props: MenuStatusChangeModalProps) => {
  const { onClose, isOpen, menuInfo, ...rest } = props;

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  if (!isOpen || !menuInfo) return null;

  const { id, name, status } = menuInfo;

  const handleChangeStatus = async () => {
    try {
      const categoryId = searchParams.get('categoryId');
      await MenuApi.menuStatusChange({ id, status });
      await queryClient.invalidateQueries({
        queryKey: MenuQueryKeys.menuList(categoryId),
      });
      onClose();
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} canOutsideClickClose={false} {...rest}>
      <h3 className="mt-6 text-center text-2xl">
        <b>{name}</b> - хотите <b>{statusText[status]}</b> этот меню?
      </h3>
      <div className="mt-6 grid grid-cols-2 gap-2">
        <Button variant="success" onClick={handleChangeStatus} className="text-white">
          Да
        </Button>
        <Button onClick={onClose} variant="primary">
          Нет
        </Button>
      </div>
    </Modal>
  );
};
