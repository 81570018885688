import type { PaginationParams, ResponseWithPagination } from '@/api/types/common.types';
import type { OrderList, OrderDetails } from '@/api/types/order.types';
import { OrderStatusEnum, PaymentTypeEnum } from '@/enums/order.enum';
import { httpClient } from '@/services/http-client';

export const OrderApi = {
  orderList: async (
    params: PaginationParams & { search?: string | null; statuses?: OrderStatusEnum },
  ) => {
    const { data } = await httpClient.get<ResponseWithPagination<OrderList>>(
      '/cashier/order/list',
      { params },
    );

    return data;
  },

  // =================================================================

  orderDetails: async (id: number | string) => {
    const { data } = await httpClient.get<OrderDetails>(`/cashier/order/${id}`);

    return data;
  },

  // =================================================================

  orderPayment: async (params: {
    id: string;
    payments: { price: number; payment_type: PaymentTypeEnum }[];
  }) => {
    const { id, payments } = params;

    return await httpClient.put(`/cashier/order/payment/${id}`, {
      payments,
    });
  },

  // =================================================================

  orderPrintOut: async (id: number) => {
    return await httpClient.get(`/cashier/order/print/check/${id}`);
  },
};
