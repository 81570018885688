import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { TableApi } from '@/api/domains/table.api';
import type { ErrorResponse } from '@/api/types/common.types';

import { useTableCategory } from '@/pages/tables/useTableCategory';
import { useTableList } from '@/pages/tables/useTableList';

import { TableQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { TextInput } from '@/components/Inputs/TextInput';
import { type ModalProps, Modal } from '@/components/Modal';
import { Select } from '@/components/Select';

// =================================================================

type FormValues = {
  table: {
    label: string;
    value: string;
  };
  bookingDate: string;
  bookingTime: string;
  username: string;
  phone: string;
};

interface TableBookingModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {}

// =================================================================

const initialValues = {
  table: { label: '', value: '' },
  bookingDate: '',
  bookingTime: '',
  username: '',
  phone: '',
};

// =================================================================

export const TableBookingModal = (props: TableBookingModalProps) => {
  const { onClose, isOpen, ...rest } = props;

  const [{ isLoading, errorMsg }, setFormStatus] = useState({ isLoading: false, errorMsg: '' });

  const { tableList } = useTableList();
  const { categoryList } = useTableCategory();
  const queryClient = useQueryClient();

  const tableListOptions = useMemo(() => {
    if (!tableList) {
      return [
        {
          label: '',
          value: '',
        },
      ];
    }

    return tableList.results.map(table => ({
      value: table.id.toString(),
      label: table.number.toString(),
    }));
  }, [tableList]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
      reset();
      setFormStatus({ isLoading: false, errorMsg: '' });
    };
  }

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { bookingDate, bookingTime, phone, username, table } = data;
      setFormStatus(prev => ({ ...prev, isLoading: true }));
      const transformedBookingDate = bookingDate.split('-').reverse().join('.');

      await TableApi.tableBooking({
        table_id: Number(table.value),
        book_time: transformedBookingDate + ' ' + bookingTime,
        client_name: username,
        client_phone: '998' + phone,
      });

      if (categoryList) {
        // to do, temporary solution
        categoryList.results.forEach(async hall => {
          await queryClient.invalidateQueries({
            queryKey: TableQueryKeys.tableList(hall.id),
            exact: true,
            refetchType: 'active',
          });
        });
      }

      onCloseRef.current?.();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { statusText } = error.response as ErrorResponse;
      setFormStatus(prev => ({ ...prev, errorMsg: statusText }));
    } finally {
      setFormStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseRef.current} canOutsideClickClose={false} {...rest}>
      <Modal.Title className="mb-6 text-center">Забронировать стол</Modal.Title>
      {errorMsg && <Alert message={errorMsg} type="error" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="table"
          control={control}
          render={({ field }) => {
            const { value, ...rest } = field;
            return (
              <Select
                {...rest}
                required
                label="Выберите стол"
                placeholder="Выберите стол"
                closeMenuOnSelect
                className="mb-3"
                options={tableListOptions}
              />
            );
          }}
        />
        <div className="flex items-center gap-4">
          <Controller
            name="bookingDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextInput
                  label="Дата бронирование"
                  type="date"
                  placeholder="Укажите дату"
                  wrapperClassName="w-full"
                  {...field}
                />
              );
            }}
          />
          <Controller
            name="bookingTime"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextInput
                  label="Время бронирование"
                  placeholder="hh:mm"
                  wrapperClassName="w-full"
                  {...field}
                />
              );
            }}
          />
        </div>
        <Controller
          name="username"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return <TextInput label="ФИО клиента" placeholder="Введите ФИО" {...field} />;
          }}
        />
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextInput
                label="Номер телефона"
                type="number"
                prefix="+998"
                placeholder="+998"
                className="mb-6"
                {...field}
              />
            );
          }}
        />
        <Button type="submit" variant="primary" disabled={isLoading} className="w-full">
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
