import type { ResponseWithPagination } from '@/api/types/common.types';
import type { MenuCategory, MenuListResponse } from '@/api/types/menu.types';
import { MenuStatusEnum } from '@/enums/menu.enum';
import { httpClient } from '@/services/http-client';

export const MenuApi = {
  menuCategory: async () => {
    const { data } = await httpClient.get<ResponseWithPagination<MenuCategory>>(
      '/cashier/menu-category/list',
    );

    return data;
  },

  // =================================================================

  menuList: async (params: { category_id?: string | number } = {}) => {
    const { data } = await httpClient.get<ResponseWithPagination<MenuListResponse>>(
      '/cashier/menu/list',
      {
        params,
      },
    );

    return data;
  },

  // =================================================================================================

  menuStatusChange: async (params: { id: number; status: MenuStatusEnum }) => {
    const { id, status } = params;

    return await httpClient.patch(`/cashier/menu/${id}`, { status });
  },
};
