import { MemoryRouter, BrowserRouter } from 'react-router-dom';

import { IPropsChildren } from '@/types/common.types';

export const RouterProvider = (props: IPropsChildren) => {
  const { children } = props;

  if (process.env.NODE_ENV === 'production') {
    return <MemoryRouter>{children}</MemoryRouter>;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
};
