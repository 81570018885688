import type { ResponseWithPagination } from '@/api/types/common.types';
import type {
  TableCategory,
  TableList,
  TableCreateParams,
  TableCreateResponse,
  TableBookingParams,
  QrCodeGenerator,
} from '@/api/types/table.types';
import { httpClient } from '@/services/http-client';

export const TableApi = {
  categoryList: async () => {
    const { data } =
      await httpClient.get<ResponseWithPagination<TableCategory>>('/cashier/hall/list');

    return data;
  },

  // =================================================================

  tableList: async (hall_id: number | undefined) => {
    const { data } = await httpClient.get<ResponseWithPagination<TableList>>(
      '/cashier/table/list',
      {
        params: { hall_id },
      },
    );

    return data;
  },

  // =================================================================

  tableCreate: async (body: TableCreateParams) => {
    const { data } = await httpClient.post<Array<TableCreateResponse>>(
      '/cashier/table/create',
      body,
    );

    return data;
  },

  // =================================================================

  tableBooking: async (body: TableBookingParams) => {
    const { data } = await httpClient.post<Array<TableCreateResponse>>(
      '/cashier/book-table/create',
      body,
    );

    return data;
  },

  // =================================================================

  qrCodeGenerate: async (tableIds: number[]) => {
    const { data } = await httpClient.post<QrCodeGenerator>('/cashier/generate/qr', {
      tables: tableIds,
    });

    return data;
  },
};
