import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

// =================================================================

interface TableContentProps {
  children: ReactNode;
  className?: string;
}

// =================================================================

export const TableContent = (props: TableContentProps) => {
  const { children, className } = props;

  return <div className={twMerge('grid grid-cols-6 gap-3', className)}>{children}</div>;
};
