import { useQueryClient } from '@tanstack/react-query';
import { useRef, Fragment, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { TableApi } from '@/api/domains/table.api';
import type { ErrorResponse } from '@/api/types/common.types';

import { useTableCategory } from '@/pages/tables/useTableCategory';

import { TableQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { TextInput } from '@/components/Inputs/TextInput';
import { type ModalProps, Modal } from '@/components/Modal';
import { Select } from '@/components/Select';

// =================================================================

type FormValues = {
  from: string;
  to: string;
  capacity?: string;
  halls: {
    label: string;
    value: string;
  };
};

interface TableCreateModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {}

// =================================================================

const initialValues = {
  from: '',
  to: '',
  capacity: '4',
  halls: {},
};

// =================================================================

export const TableCreateModal = (props: TableCreateModalProps) => {
  const { onClose, isOpen, ...rest } = props;

  const [{ isLoading, errorMsg }, setFormStatus] = useState({ isLoading: false, errorMsg: '' });

  const { categoryList } = useTableCategory();
  const queryClient = useQueryClient();

  const halls = useMemo<Array<{ label: string; value: string }>>(() => {
    if (!categoryList) {
      return [
        {
          label: '',
          value: '',
        },
      ];
    }

    return categoryList.results.map(category => ({
      label: category.name,
      value: category.id.toString(),
    }));
  }, [categoryList]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: initialValues,
    // values: { ...initialValues, halls: halls[0] },
  });

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
      reset();
      setFormStatus({ isLoading: false, errorMsg: '' });
    };
  }

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { from, halls, to, capacity } = data;
      setFormStatus(prev => ({ ...prev, isLoading: true }));

      await TableApi.tableCreate({
        capacity: capacity ? parseInt(capacity) : 4,
        from: parseInt(from),
        to: parseInt(to),
        hall_id: parseInt(halls.value),
      });

      await queryClient.invalidateQueries({
        queryKey: TableQueryKeys.tableList(halls.value),
        exact: true,
        refetchType: 'active',
      });

      onCloseRef.current?.();
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { statusText } = error.response as ErrorResponse;
      setFormStatus(prev => ({ ...prev, errorMsg: statusText }));
    } finally {
      setFormStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseRef.current} canOutsideClickClose={false} {...rest}>
      <Modal.Title className="mb-6 text-center">Добавить новый стол</Modal.Title>
      {errorMsg && <Alert message={errorMsg} type="error" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center gap-4">
          <Controller
            name="from"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextInput
                  autoFocus
                  placeholder="Введите номер стола"
                  label=<Fragment>
                    Номер стола с (number)<sup className="text-lg text-primary">*</sup>
                  </Fragment>
                  {...field}
                />
              );
            }}
          />
          <Controller
            name="to"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextInput
                  label=<Fragment>
                    до (number)
                    <sup className="text-lg text-primary">*</sup>
                  </Fragment>
                  placeholder="Введите номер стола"
                  {...field}
                />
              );
            }}
          />
        </div>
        <Controller
          name="capacity"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextInput
                label="Вместимость стола (number)"
                placeholder="Введите количество"
                {...field}
              />
            );
          }}
        />
        <Controller
          name="halls"
          control={control}
          render={({ field }) => {
            const { value, ...rest } = field;
            return (
              <Select
                {...rest}
                required
                label="Расположение стола"
                placeholder="Выберите зал"
                closeMenuOnSelect
                className="mb-6"
                options={halls}
              />
            );
          }}
        />
        <Button type="submit" variant="primary" disabled={isLoading} className="w-full">
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
