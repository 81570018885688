import { useQuery } from '@tanstack/react-query';

import { TableApi } from '@/api/domains/table.api';

import { TableQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useTableCategory = () => {
  const { data, isLoading } = useQuery({
    queryKey: TableQueryKeys.categoryList(),
    queryFn: TableApi.categoryList,
    staleTime: Infinity,
  });

  return { categoryList: data, isLoading };
};
