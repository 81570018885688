import { useState } from 'react';

import { OrderApi } from '@/api/domains/order.api';

import { Button } from '@/components/Button';

import { ReactComponent as PrinterSVG } from '@/assets/icons/printer.svg';

export const OrderPrintoutButton = (props: { orderId: number }) => {
  const { orderId } = props;

  const [isPending, setIsPending] = useState(false);

  const printOut = async (id: number) => {
    try {
      setIsPending(true);
      await OrderApi.orderPrintOut(id);
    } catch (err) {
      throw err;
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Button
      variant="success"
      onClick={() => printOut(orderId)}
      disabled={isPending}
      className="text-white"
    >
      <PrinterSVG width={28} height={28} />
    </Button>
  );
};
