import { useRef, memo } from 'react';
import { useUpdate } from 'react-use';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Mousewheel } from 'swiper/modules';
import { twMerge } from 'tailwind-merge';

import { IClassName } from '@/types/common.types';

import { useMenuCategory } from '@/pages/menu/useMenuCategory';

import { MenuSlide } from '@/pages/menu/MenuSlide';

import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';

// =================================================================

const navigationBtnClassName =
  'absolute top-1/2 z-[1] flex h-10 min-h-10 w-10 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-gray p-0 transition-colors hover:text-primary disabled:pointer-events-none disabled:opacity-30';

// =================================================================

export const MenuCategoryList = memo((props: IClassName) => {
  const { className } = props;

  const update = useUpdate();

  const previousSlideButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextSlideButtonRef = useRef<HTMLButtonElement | null>(null);

  const { isLoading, menuCategory } = useMenuCategory();

  if (!menuCategory && isLoading) {
    return null;
  }

  return (
    <div className={twMerge('relative', className)}>
      <Swiper
        wrapperTag="ul"
        spaceBetween={28}
        slidesPerView={15}
        slidesPerGroup={4}
        mousewheel={{
          forceToAxis: true,
          thresholdDelta: 25,
          thresholdTime: 50,
        }}
        navigation={{
          prevEl: previousSlideButtonRef.current,
          nextEl: nextSlideButtonRef.current,
        }}
        modules={[Navigation, Mousewheel]}
        onBeforeInit={update}
      >
        {menuCategory!.results.map(category => (
          <SwiperSlide key={category.id}>
            <MenuSlide menuCategory={category} />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        ref={previousSlideButtonRef}
        type="button"
        className={twMerge('-left-5', navigationBtnClassName)}
      >
        <ArrowLeftSVG />
      </button>
      <button
        ref={nextSlideButtonRef}
        type="button"
        className={twMerge('-right-5', navigationBtnClassName)}
      >
        <ArrowLeftSVG className="rotate-180" />
      </button>
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MenuCategoryList.displayName = 'MenuCategoryList';
}
