import { Fragment, useState, useEffect } from 'react';

import { twMerge } from 'tailwind-merge';

import { useTableCategory } from '@/pages/tables/useTableCategory';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { GenerateQrCodeModal } from '@/pages/tables/GenerateQrCodeModal';
import { HallTablesView } from '@/pages/tables/HallTablesView';
import { TableBookingModal } from '@/pages/tables/TableBookingModal';
import { TableCreateModal } from '@/pages/tables/TableCreateModal';

export const TablesPage = () => {
  const [tableCreateModal, setTableCreate] = useState(false);
  const [bookingTableModal, setBookingTableModal] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [categoryId, setCategoryId] = useState<number>();

  const { categoryList } = useTableCategory();

  useEffect(() => {
    if (categoryList) {
      setCategoryId(() => categoryList.results[0].id);
    }
  }, [categoryList]);

  return (
    <Fragment>
      <div className="mb-4 flex items-center justify-between">
        <PageTitle title="Столы" />
        <div className="flex items-center gap-2">
          <Button
            variant="info"
            onClick={() => setQrCodeModal(true)}
            className="text-base font-medium text-white"
          >
            Сгенерировать QR
          </Button>
          <Button
            variant="warning"
            onClick={() => setBookingTableModal(true)}
            className="text-base font-medium text-white"
          >
            Забронировать стол
          </Button>
          <Button
            variant="accent"
            onClick={() => setTableCreate(true)}
            className="text-base font-medium text-white"
          >
            Добавить стол
          </Button>
        </div>
      </div>
      {categoryList && (
        <ul className="flex items-center gap-2 py-8">
          {categoryList.results.map(category => (
            <li key={category.id}>
              <Button
                variant={categoryId === category.id ? 'primary' : 'neutral'}
                onClick={() => setCategoryId(category.id)}
                className={twMerge(
                  'min-w-24 rounded-3xl px-3 py-2 text-sm font-medium text-black',
                  categoryId === category.id && 'text-white',
                )}
              >
                {category.name}
              </Button>
            </li>
          ))}
        </ul>
      )}
      <HallTablesView categoryId={categoryId} />
      <TableCreateModal isOpen={tableCreateModal} onClose={() => setTableCreate(false)} />
      <TableBookingModal isOpen={bookingTableModal} onClose={() => setBookingTableModal(false)} />
      <GenerateQrCodeModal isOpen={qrCodeModal} onClose={() => setQrCodeModal(false)} />
    </Fragment>
  );
};
