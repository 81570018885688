import { generatePath } from 'react-router';

import { join } from '@/contexts/RouterContext/router.helpers';
import {
  AuthPaths,
  CashPaths,
  TablePaths,
  ExpensePaths,
  MenuPaths,
  OrderPaths,
  PickUpPaths,
} from '@/contexts/RouterContext/router.paths';

export const AuthPageLinks = {
  main: AuthPaths.ROOT_PATH,

  login: join(AuthPaths.ROOT_PATH, AuthPaths.LOGIN),
} as const;

// =============================================================================

export const CashPageLinks = {
  main: CashPaths.ROOT_PATH,
} as const;

// =============================================================================

export const TablePageLinks = {
  main: TablePaths.ROOT_PATH,
} as const;

// =============================================================================

export const OrderPageLinks = {
  main: OrderPaths.ROOT_PATH,

  details: (id: string | number) => generatePath(OrderPaths.DETAILS, { id: id.toString() }),
} as const;

// =============================================================================

export const MenuPageLinks = {
  main: MenuPaths.ROOT_PATH,
} as const;

// =============================================================================

export const ExpensePageLinks = {
  main: ExpensePaths.ROOT_PATH,
} as const;

// =============================================================================

export const PickUpPageLinks = {
  main: PickUpPaths.ROOT_PATH,
} as const;
