import { LazyLoadImage } from 'react-lazy-load-image-component';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';

import { ReactComponent as MinusSVG } from '@/assets/icons/minus.svg';
import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';
import imagePlaceholder from '@/assets/images/placeholder.png';

// =================================================================

interface PickMenuCardUIProps {
  imageSrc: string;
  title: string;
  price: number;
  count: number;
  handleDecreament: VoidFunction;
  handleIncreament: VoidFunction;
}

// =================================================================

export const PickMenuCardUI = (props: PickMenuCardUIProps) => {
  const { count, handleDecreament, handleIncreament, imageSrc, price, title } = props;

  return (
    <li className="mb-4 flex items-center gap-6 rounded-lg border border-gray-300 p-2">
      <div className="w-20 overflow-hidden rounded-sm border border-gray-300 bg-gray-200 p-1">
        <LazyLoadImage
          src={imageSrc}
          alt={title}
          style={{ aspectRatio: 7 / 5 }}
          className="w-full object-contain object-center"
          placeholderSrc={imagePlaceholder}
        />
      </div>
      <h3 className="w-1/2 text-lg font-medium">{title}</h3>
      <p>{thousandSeperator(price)} сум</p>
      <div className="ml-auto flex items-center gap-4">
        <Button variant="neutral" disabled={count === 0} onClick={handleDecreament}>
          <MinusSVG width={18} height={18} />
        </Button>
        <p className="w-7 text-center text-lg font-medium">{count}</p>
        <Button variant="neutral" onClick={handleIncreament}>
          <PlusSVG width={18} height={18} />
        </Button>
      </div>
    </li>
  );
};
