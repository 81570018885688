import { useSearchParams } from 'react-router-dom';

export const useDate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDateChange = (params: { value: string; query: string }) => {
    const { value, query } = params;
    if (!value) {
      searchParams.delete(query);
      setSearchParams(searchParams);
    }

    const transformedValue = value.split('-').reverse().join('-');
    setSearchParams({ [query]: transformedValue });
  };

  return { handleDateChange };
};
