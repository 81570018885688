import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { StatisticsApi } from '@/api/domains/statistics.api';

import { StatisticsQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useDailyStatistics = () => {
  const currentDate = new Date();
  const day = ('00' + currentDate.getDate()).slice(-2);
  const month = ('00' + (currentDate.getMonth() + 1)).slice(-2);
  const year = currentDate.getFullYear();

  const date = `${day}-${month}-${year}`;

  const [searchParams] = useSearchParams();
  const queryDate = searchParams.get('fromDate');

  const { data } = useQuery({
    queryKey: StatisticsQueryKeys.daily(queryDate || date),
    queryFn: () => StatisticsApi.dailyStatistics({ date: queryDate || date }),
    staleTime: 0,
  });

  return data;
};
