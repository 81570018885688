import { memo, Fragment, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { twMerge } from 'tailwind-merge';

import type { MenuList } from '@/api/types/menu.types';
import { MenuStatusEnum } from '@/enums/menu.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';

import { MenuStatusChangeModal } from '@/pages/menu/MenuStatusChangeModal';

import imagePlaceholder from '@/assets/images/placeholder.png';

// =================================================================

interface MenuCardProps {
  menu: MenuList;
}

// =================================================================

const statusText: Record<MenuStatusEnum, string> = {
  active: 'Активный',
  inactive: 'Неактивный',
};

// =================================================================

export const MenuCard = memo((props: MenuCardProps) => {
  const { menu } = props;

  const [menuInfo, setMenuInfo] = useState<{
    id: number;
    name: string;
    status: MenuStatusEnum;
  } | null>(null);

  const handleChangeMenuStatus = (status: MenuStatusEnum) => {
    if (status === menu.status) {
      return;
    }

    setMenuInfo({
      id: menu.id,
      status,
      name: menu.name,
    });
  };

  return (
    <Fragment>
      <div className="flex w-full flex-col justify-between overflow-hidden rounded-2xl border border-gray">
        <LazyLoadImage
          src={menu.photos[0]}
          alt={menu.name}
          style={{ aspectRatio: 7 / 5 }}
          className="w-full object-contain object-center"
          placeholderSrc={imagePlaceholder}
        />
        <div className="p-4">
          <Alert
            message={statusText[menu.status]}
            type={menu.status === MenuStatusEnum.ACTIVE ? 'success' : 'error'}
            className="mb-4 block w-fit rounded-md px-2 py-1"
          />
          <h3 className="mb-2 text-2xl font-semibold">{menu.name}</h3>
          {menu.description && (
            <p className="mb-4 text-lg leading-6 text-gray-400">{menu.description}</p>
          )}
          <p className="mb-4 text-2xl font-semibold text-primary">
            {thousandSeperator(menu.price)} сум
          </p>
          <div className="flex items-center justify-between">
            <Button
              variant={menu.status === MenuStatusEnum.ACTIVE ? 'success' : 'neutral'}
              onClick={() => handleChangeMenuStatus(MenuStatusEnum.ACTIVE)}
              className={twMerge(
                'text-base font-medium',
                menu.status === MenuStatusEnum.ACTIVE ? 'text-white' : 'text-black',
              )}
            >
              Доступный
            </Button>
            <Button
              variant={menu.status === MenuStatusEnum.INACTIVE ? 'primary' : 'neutral'}
              onClick={() => handleChangeMenuStatus(MenuStatusEnum.INACTIVE)}
              className={twMerge(
                'text-base font-medium',
                menu.status === MenuStatusEnum.INACTIVE ? 'text-white' : 'text-black',
              )}
            >
              Не доступный
            </Button>
          </div>
        </div>
      </div>
      <MenuStatusChangeModal
        isOpen={Boolean(menuInfo)}
        onClose={() => setMenuInfo(null)}
        menuInfo={menuInfo}
      />
    </Fragment>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MenuCard.displayName = 'MenuCard';
}
