import type { User } from '@/api/types/auth.types';
import { httpClient } from '@/services/http-client';

export const AuthApi = {
  login: async (phone: string, password: string) => {
    const { data } = await httpClient.post<User & { token: string }>('/admin/sign-in', {
      password,
      phone,
    });

    return data;
  },

  // =================================================================

  getMe: async () => {
    const { data } = await httpClient.get<User>('/cashier/me');

    return data;
  },
};
