import { useMenuList } from '@/pages/menu/useMenuList';

import { PickUpMenuCard } from '@/pages/pick-up/PickUpMenuCard';

export const MenuList = () => {
  const { menuList, isLoading } = useMenuList();

  if (!menuList || isLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div className="mt-8">
      {menuList.results.map(category => (
        <div key={category.category_id}>
          <h3 className="mb-6 text-2xl font-semibold">{category.category_name}</h3>
          {category.menus.length > 0 && (
            <ul>
              {category.menus.map(menu => (
                <PickUpMenuCard key={menu.id} menu={menu} />
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};
