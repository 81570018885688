import { Fragment } from 'react';

import { useDate } from '@/pages/cash/useDate';

import { TextInput } from '@/components/Inputs/TextInput';
import { PageTitle } from '@/components/PageTitle';

import { DailyStatistics } from '@/pages/cash/DailyStatistics';

export const CashPage = () => {
  const { handleDateChange } = useDate();

  return (
    <Fragment>
      <div className="mb-7 flex items-center justify-between">
        <PageTitle title="Общая статистика" />
        <div className="flex items-center gap-2">
          <TextInput
            type="date"
            label="Дата"
            onChange={event => {
              handleDateChange({ value: event.target.value, query: 'fromDate' });
            }}
          />
          {/* <p className="mt-6">-&gt;</p> */}
          {/* <TextInput
            type="date"
            label="До"
            onChange={event => {
              handleDateChange({ value: event.target.value, query: 'toDate' });
            }}
          /> */}
        </div>
      </div>
      <div className="rounded-2xl border bg-white">
        <DailyStatistics />
      </div>
    </Fragment>
  );
};
