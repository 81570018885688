import { useQuery } from '@tanstack/react-query';

import { OrderApi } from '@/api/domains/order.api';

import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useOrderDetails = (config: { orderId: string }) => {
  const { orderId } = config;

  const { data, isLoading } = useQuery({
    queryKey: OrderQueryKeys.orderDetails(orderId),
    queryFn: () => OrderApi.orderDetails(orderId),
    staleTime: Infinity,
    enabled: Boolean(orderId),
  });

  return { order: data, isLoading };
};
