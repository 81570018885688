import { Fragment } from 'react';

import { PageTitle } from '@/components/PageTitle';

import { MenuCategoryList } from '@/pages/menu/MenuCategoryList';
import { MenuList } from '@/pages/menu/MenuList';

// =================================================================

export const MenuPage = () => {
  return (
    <Fragment>
      <PageTitle title="Меню" className="mb-7" />
      <MenuCategoryList className="mb-8" />
      <MenuList />
    </Fragment>
  );
};
