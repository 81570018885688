import { memo } from 'react';

import { Button } from '@/components/Button';
import { Table } from '@/components/Table';

import { ReactComponent as EditSVG } from '@/assets/icons/edit.svg';
import { ReactComponent as TrashSVG } from '@/assets/icons/trash.svg';

// =================================================================

const columns = [
  {
    accessorKey: 'year',
    header: 'Дата',
  },
  {
    accessorKey: 'cardType',
    header: 'Вид операции',
  },
  {
    accessorKey: 'moduleId',
    header: 'Категория',
  },
  {
    accessorKey: 'title',
    header: 'От кого/кому',
  },
  {
    cell: () => {
      return <span>1 250 000 сум</span>;
    },
    header: 'Сумма',
  },
  {
    accessorKey: 'id',
    cell: () => {
      return (
        <div className="flex items-center gap-4">
          <Button
            variant="primary"
            className="flex h-12 min-h-12 w-12 items-center justify-center p-0"
          >
            <TrashSVG />
          </Button>
          <Button
            variant="accent"
            className="flex h-12 min-h-12 w-12 items-center justify-center p-0 text-white"
          >
            <EditSVG />
          </Button>
        </div>
      );
    },
    header: 'Действие',
  },
];

// =================================================================

export const ExpensesTable = memo(() => {
  return <Table columns={columns} pageCount={0} data={[]} isLoading={false} />;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ExpensesTable.displayName = 'ExpensesTable';
}
