import { memo } from 'react';

import {
  TablePageLinks,
  CashPageLinks,
  MenuPageLinks,
  OrderPageLinks,
  PickUpPageLinks,
} from '@/contexts/RouterContext/router.links';

import { Logo } from '@/components/Logo';
import { NavLink } from '@/components/NavLink';

import { ReactComponent as CashSVG } from '@/assets/icons/cash.svg';
import { ReactComponent as ClocheSVG } from '@/assets/icons/cloche.svg';
import { ReactComponent as CoinsSVG } from '@/assets/icons/coins.svg';
import { ReactComponent as MenuSVG } from '@/assets/icons/menu.svg';
import { ReactComponent as TableSVG } from '@/assets/icons/table.svg';

// =================================================================

const menuConfig = [
  {
    title: 'Касса',
    icon: <CashSVG />,
    href: CashPageLinks.main,
  },
  {
    title: 'Столы',
    icon: <TableSVG />,
    href: TablePageLinks.main,
  },
  {
    title: 'Заказы',
    icon: <ClocheSVG />,
    href: OrderPageLinks.main,
  },
  {
    title: 'Меню',
    icon: <MenuSVG />,
    href: MenuPageLinks.main,
  },
  {
    title: 'Подобрать',
    icon: <CoinsSVG />,
    href: PickUpPageLinks.main,
  },
  // {
  //   title: 'Приход и уход',
  //   icon: <DocumentCheckmarkSVG />,
  //   href: ExpensePageLinks.main,
  // },
];

// =================================================================

export const Sidebar = memo(() => {
  return (
    <aside className="fixed left-0 top-0 z-20 h-screen w-[360px] border-r-2 border-gray bg-white">
      <div className="p-6">
        <Logo width={152} height={42} />
      </div>
      <ul>
        {menuConfig.map(menu => (
          <li key={menu.href}>
            <NavLink to={menu.href} className="flex items-center gap-2 px-6 py-4 text-black-100">
              {menu.icon}
              <span className="text-lg">{menu.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Sidebar.displayName = 'Sidebar';
}
