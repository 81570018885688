import { Fragment } from 'react';

import { PageTitle } from '@/components/PageTitle';

import { OrdersTable } from '@/pages/orders/OrdersTable';

export const OrdersPage = () => {
  return (
    <Fragment>
      <PageTitle title="Заказы" className="mb-8" />
      <OrdersTable />
    </Fragment>
  );
};
