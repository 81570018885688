import { type ChangeEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

import { TextInput } from '@/components/Inputs/TextInput';

export const PickUpSearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange: ChangeEventHandler<HTMLInputElement> = debounce(event => {
    if (event.target.value) {
      setSearchParams({ title: event.target.value });
    } else {
      if (searchParams.get('title')) {
        searchParams.delete('title');
        setSearchParams(searchParams);
      }
    }
  }, 200);

  return (
    <TextInput
      type="text"
      label="Поиск по названию меню:"
      placeholder="Поиск..."
      onChange={handleChange}
      min={1}
    />
  );
};
