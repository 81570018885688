import { useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { AuthRouterGuard } from '@/contexts/RouterContext/AuthRouterGuard';
import {
  AuthPageLinks,
  CashPageLinks,
  TablePageLinks,
  MenuPageLinks,
  OrderPageLinks,
  ExpensePageLinks,
  PickUpPageLinks,
} from '@/contexts/RouterContext/router.links';
import { OrderPaths } from '@/contexts/RouterContext/router.paths';

// It is not working properly with service worker, Remove lazy loading or find other solution
// @url https://rollupjs.org/introduction
import { CashPage } from '@/pages/cash';
import { ExpensesPage } from '@/pages/expenses';
import { LoginPage } from '@/pages/login';
import { MenuPage } from '@/pages/menu';
import { OrderDetailsPage } from '@/pages/order-details';
import { OrdersPage } from '@/pages/orders';
import { PickUpPage } from '@/pages/pick-up';
import { TablesPage } from '@/pages/tables';

export const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <AuthRouterGuard />,
      children: [
        {
          index: true,
          element: <Navigate to={CashPageLinks.main} replace />,
        },
        {
          path: CashPageLinks.main,
          element: <CashPage />,
        },
        {
          path: TablePageLinks.main,
          element: <TablesPage />,
        },
        {
          path: MenuPageLinks.main,
          element: <MenuPage />,
        },
        {
          path: OrderPageLinks.main,
          element: <OrdersPage />,
        },
        {
          path: OrderPaths.DETAILS,
          element: <OrderDetailsPage />,
        },
        {
          path: ExpensePageLinks.main,
          element: <ExpensesPage />,
        },
        {
          path: PickUpPageLinks.main,
          element: <PickUpPage />,
        },
      ],
    },

    // Login Page
    {
      path: AuthPageLinks.main,
      element: <Navigate to={AuthPageLinks.login} replace />,
    },
    {
      path: AuthPageLinks.login,
      element: <LoginPage />,
    },

    // Not found page
    {
      path: '/404',
      element: <h1>Not found!</h1>,
    },

    { path: '*', element: <Navigate to={'/404'} replace /> },
  ]);
};
