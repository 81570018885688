import { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { Badge, type BadgeType } from '@/components/Badge';
import { Button } from '@/components/Button';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { OrderInfoModal } from '@/components/OrderInfoModal';
import { PageTitle } from '@/components/PageTitle';

import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';

// =================================================================

const orderStatusType: Record<OrderStatusEnum, BadgeType> = {
  NEW: 'accent',
  PAID: 'success',
  CANCELLED: 'error',
  SERVED: 'info',
};

// =================================================================

export const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState('');

  const params = useParams();

  const { order, isLoading } = useOrderDetails({ orderId: params.id as string });

  return (
    <Fragment>
      <div className="mb-6 flex items-center gap-4">
        <Button variant="neutral" onClick={() => navigate(-1)} className="h-fit min-h-fit p-2">
          <ArrowLeftSVG width={24} height={24} />
        </Button>
        <PageTitle title={`Заказы № ${params.id}`} />
      </div>
      {!order && isLoading && <h2>Loading...</h2>}
      {order && (
        <div className="grid grid-cols-2 gap-6">
          <OrderDetailsCard>
            <OrderDetailsCard.Title className="mb-4">Информация о столе</OrderDetailsCard.Title>
            <OrderDetailsCard.Price
              leftText="Номер стола"
              rightText={`№${order.table_number} - Внутренный`}
              className="mb-4"
            />
            <OrderDetailsCard.Price
              leftText="Количество гостей"
              rightText={`${order.client_count} человека`}
              className="mb-4"
            />
            <OrderDetailsCard.Price
              leftText="ФИО официанта"
              rightText={order.waiter.name}
              className="mb-8"
            />
            <div className="mb-4 flex items-center justify-between">
              <OrderDetailsCard.Title>Информация о заказе</OrderDetailsCard.Title>
              <Badge
                text={order.status}
                type={orderStatusType[order.status]}
                className="px-5 py-4 text-base text-white"
              />
            </div>
            {order.menus.map(menu => (
              <OrderDetailsCard.Price
                key={menu.order_menu_id}
                leftText={`${menu.count} х “${menu.name}”`}
                rightText={`${thousandSeperator(menu.price)} сум`}
                className="mb-4"
              />
            ))}
            <div className="mb-4 mt-8 flex items-center justify-between">
              <OrderDetailsCard.Title>Итого</OrderDetailsCard.Title>
              <OrderDetailsCard.Title>{thousandSeperator(order.price)} сум</OrderDetailsCard.Title>
            </div>
            <OrderDetailsCard.Price
              leftText={`Обслуживание ${order.service_percentage}%`}
              rightText={`${thousandSeperator(order.service_price)} сум`}
              className="mb-10"
            />
            {order.status === OrderStatusEnum.NEW && (
              <div className="text-right">
                <Button
                  variant="primary"
                  onClick={() => setOrderId(params.id as string)}
                  className="px-12 text-base"
                >
                  Закрыть заказ
                </Button>
              </div>
            )}
          </OrderDetailsCard>
          <OrderDetailsCard>
            <OrderDetailsCard.Title className="mb-6">Информация о столе</OrderDetailsCard.Title>
            {Array(6)
              .fill(null)
              .map((_: null, index: number) => (
                <OrderDetailsCard.TableInfo
                  key={index}
                  title="Новый заказ"
                  time="15:20"
                  desc="Заказ №1 был создан официантом для стола №3."
                  isLast={index === 5}
                />
              ))}
          </OrderDetailsCard>
        </div>
      )}
      <OrderInfoModal orderId={orderId} isOpen={Boolean(orderId)} onClose={() => setOrderId('')} />
    </Fragment>
  );
};
